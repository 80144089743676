<template>
    <div class="question">
        <s-header :name="$t('LAN.question_common')"></s-header>
        <div class="content" :style="$L(1)">
            <form action="action">
                <van-search
                    v-model="search_val"
                    show-action
                    :placeholder="$t('LAN.search_placeholder')"
                    @search="onSearch"
                    @input="onInput"
                    @focus="onFocus"
                    @blur="onBlur"
                    @clear="onClear">
                    <template #action>
                        <div @click="onSearch">{{$t('LAN.search')}}</div>
                    </template>
                </van-search>
            </form>

            <div class="srarch_main" v-show="search && searchList.length">
                <ul class="list">
                    <li v-for="(item, key) in searchList" :key="key" v-html="item.showQues" @mousedown="onClickSearch(item)"/>
                </ul>
            </div>

            <div class="ques-content">
                <van-empty image="search" :description="$t('LAN.search_empty')" v-show="emptyShow" />
                <van-collapse v-model="activeName" accordion>
                    <van-collapse-item v-for="(val, key) in questions" :title="val.name" :name="val.type" :key="key">
                        <div class="ques-list">
                            <ul class="list">
                                <li v-for="item in val.list" :key="item.id" @click="onClickQues(item)">{{item.question}}</li>
                            </ul>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import sHeader from '@/components/Header'
import { Toast } from 'vant'
import { useRouter, useRoute } from 'vue-router'
import { validateNull } from '@/utils/validate';
import { loadConfig, searchQuestions, saveSearchHistory} from '@/utils/questions'
import { useI18n } from 'vue-i18n'
import { setStore, getStore } from '@/utils/store'

export default {
    setup() {
        const router = useRouter()
        const route = useRoute()
        const { t } = useI18n()
        
        const state = reactive({
            activeName: getStore({ name: 'quesActiveName' }) || "1",
            questionsCfg:null,
            search_val:"",
            emptyShow:false,
            search:false,
            searchList:[],
            tabNames: {
                1:t('LAN.questionTabs_1'),
                2:t('LAN.questionTabs_2'),
                3:t('LAN.questionTabs_3'),
                4:t('LAN.questionTabs_4'),
            },
            questions:{},
        });

        onMounted(() => {
            init()
        });

        const init = async () => {
            Toast.loading(t('LAN.loading'));
            loadConfig(initData);
        }
        
        const initData = (questionsCfg) => {
            state.questionsCfg = questionsCfg
            if (route.query && route.query.search) {
                state.search_val = route.query.search;
            }
            onSearch();
            Toast.clear()
        }

        const onSearch = () => {
            saveSearchHistory(state.search_val);
            
            state.questions = {}
            let questionsCfg = state.questionsCfg
            let flag = false;
            if (questionsCfg) {
                for (const key in questionsCfg) {
                    let tabData = state.questions[key]
                    if (!tabData) {
                        tabData = {
                            name:state.tabNames[key],
                            type:key,
                            list:[]
                        };
                    }
                    for (const id in questionsCfg[key]) {
                        let item = questionsCfg[key][id];
                        if (validateNull(state.search_val)) {
                            tabData.list.push(item)
                        } else if (item.question.indexOf(state.search_val) > -1)  {
                            tabData.list.push(item)
                        }
                    }
                    if (tabData.list.length > 0) {
                        state.questions[key] = tabData;
                        if (!flag) {
                            if (!validateNull(state.search_val)) {
                                state.activeName = key
                            } else {
                                state.activeName = getStore({ name: 'quesActiveName' }) || "1"
                            }
                            flag = true
                        }
                    }
                }
            }
            state.emptyShow = Object.keys(state.questions).length <= 0;
        }

        // 获得输入框焦点
        const onFocus = () => {
            state.search = true;
            onInput();
        }

        const onBlur = () => {
            state.search = false;
        }

        const onInput = () => {
            state.searchList = searchQuestions(state.questionsCfg, state.search_val)
        }

        const onClickSearch = (item) => {
            state.searchList = []
            state.search_val = item.question;
            onSearch();
        }
        
        const onClear = () => {
            onInput();
            onSearch();
        }

        const goTo = (name, params) => {
            router.push({ name: name, query:params})
        }

        const onClickQues = (item) => {
            setStore({ name: 'quesActiveName', content: state.activeName, type:'session' })
            goTo('questionsDetailLink', item)
        }

        return {
            ...toRefs(state),
            goTo,
            onSearch,
            onFocus,
            onBlur,
            onInput,
            onClear,
            onClickSearch,
            onClickQues
        }
    },
    components: {
        sHeader,
    },
}
</script>

<style lang="less" scoped>
@import '../../../style/mixin.less';

.question {
    height: 100vh;
    background-color: white;
    
    .content {
        text-align: left;
        padding: 0 5.333vw;
        .ques-content {
            .ques-list li {
                padding: 5px 0;
                font-size: 16px;
                position: relative;
                border-top: 1px solid #ebedf0;
                &:first-child {
                    border-top: 0px solid #ebedf0;
                }
            }
        }
        
        .srarch_main {
            position: absolute;
            z-index: 10;
            background-color: #F7F8FA;
            border-radius: 5px;
            top: 95px;
            padding: 0 10px;
            width: 300px;
            left:34px;
            box-sizing: border-box;
            padding: var(--van-search-padding);

            .list {
                li {
                    display: list-item;
                    line-height: 35px;
                    border-bottom: 1px solid #eee;
                    font-size: 16px;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                    &:last-child {
                        border-bottom: 0px;
                    }
                }
            }
            .search-icon {
                font-size: 16px;
                line-height: inherit;
            }
        }
    }
}
</style>

